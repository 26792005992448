// Dependencies
import React from "react"
import { Link } from "gatsby"
// Hooks & Helpers
import useVariantInfo from "../hooks/useVariantInfo"
// Wrappers
// ...
// Components
import Image from "../components/Image"
import Rollovers, {
  RolloverPopup,
  useThumbnailRollovers,
} from "../components/Rollovers"

const PriceRollover = ({ isAvailable, sizes, compareAtPrice, price }) => {
  return isAvailable ? (
    <span>
      <span className="shop__thumb__price">
        <span className="strikethru">{compareAtPrice}</span> {price}
      </span>
      {sizes && sizes.length > 1 ? (
        <span className="shop__thumb__sizes">
          {sizes.map((size, i) => (
            <span
              style={{ opacity: size.available ? 1 : 0.33 }}
              key={`${size.value}${i}`}
            >
              {size.value}{" "}
            </span>
          ))}
        </span>
      ) : (
        <span className="shop__thumb__sizes">
          <span className="strikethru">{compareAtPrice}</span> {price}
        </span>
      )}
    </span>
  ) : (
    <span className="upcase">— Sold out —</span>
  )
}

const getSizeOption = (options) =>
  options?.find(
    (o) => o.name === "Size" || o.name === "SIZE" || o.name === "size"
  )

export const ProductThumbnailInfo = ({ product }) => {
  const { title, colourMaterial, variants, comingSoon, comingSoonLabel } =
    product
  const { compareAtPrice, price } = useVariantInfo(variants[0])
  const isAvailable = variants.find((variant) => variant.availableForSale)
    ? true
    : false

  // Sizes
  const optionSizes = getSizeOption(product?.options)?.values
  const variantSizes = variants.map((item) => {
    // const shortenSize = str => str ? str.substring(0, 3).replace(" ", "") : null

    return {
      // value: shortenSize(item.selectedOptions[0]?.value) || '?',
      value: item.selectedOptions[0]?.value,
      available: item.availableForSale,
    }
  })
  const sizes = optionSizes
    ? optionSizes.map((o) => {
        return variantSizes.find((v) => v.value === o)
      })
    : variantSizes

  return (
    <div className="p3">
      <p className="shop__thumb__info">
        {title} <br />
        {colourMaterial} <br />
        {!comingSoon ? (
          <PriceRollover
            isAvailable={isAvailable}
            compareAtPrice={compareAtPrice}
            price={price}
            sizes={sizes}
          />
        ) : (
          <span className="upcase">{comingSoonLabel ?? "Coming soon"}</span>
        )}
      </p>
    </div>
  )
}

const ProductThumbnail = ({
  className,
  product,
  isRolloverActive,
  enterRollover,
  leaveRollover,
  style = {},
  debug,
}) => {
  // Content
  const { handle } = product

  const initialProductType = product.type?.filter(
    (t) => t.slug !== "new-arrivals"
  )[0]

  // Use thumbnails from contentful
  const images = product.thumbnails || product.images
  const featuredImage =
    (product.thumbnails && product.thumbnails[0].large) ||
    product.featuredImage?.gatsbyImageData

  // UX
  const [showRollover, showThumbnails, hideThumbnails] = useThumbnailRollovers(
    enterRollover,
    leaveRollover
  )

  const css = {
    ...style,
    opacity: isRolloverActive ? (showRollover ? 1 : 1) : 1,
    transition: "opacity 0.5s",
  }

  return (
    <Link
      to={`/product/${handle}`}
      state={{ productType: initialProductType?.slug }}
      className={`thumb ${className} ${showRollover ? "hover" : ""}`}
      onMouseEnter={showThumbnails}
      onMouseLeave={hideThumbnails}
      style={css}
    >
      {featuredImage && <Image image={featuredImage} className="img" />}

      <ProductThumbnailInfo product={product} />

      {isRolloverActive && showRollover && (
        <Rollovers>
          {images.map(
            (image, index) =>
              index > 0 &&
              index < 4 && (
                <RolloverPopup index={index - 1} key={`rollover-${index}`}>
                  <Image image={image.medium} />
                </RolloverPopup>
              )
          )}
        </Rollovers>
      )}
    </Link>
  )
}

export default ProductThumbnail
