// Dependencies
import React, { useState, useEffect, useCallback } from 'react'
// Components
import Input from '../components/Input'
import { useNavigationContext } from "../wrappers/Navigation"

const LIST_ID = 'TtFAHj'

const Checkbox = (props) => {

	const { className, id, tabIndex, type, name, value } = props
	const inputProps = { id, tabIndex, type, name, value }

	return (
		<div className={className}>
			<input {...inputProps} />
			<label htmlFor={id}>{value}</label>
		</div>
	)
}

export const NewsletterPopup = ({ delay = 3000 }) => {

	const { newsletterState, cookiesState } = useNavigationContext()
	const [isHidden, setHidden] = newsletterState
	const [cookiesAccepted] = cookiesState
	
	const [show, set] = useState(false)

	const handleVisibility = useCallback(() => {
		if (!isHidden) {
			set(true)
		}
	}, [isHidden])

	useEffect(() => {
		const timer = setTimeout(() => {
			handleVisibility()
		}, delay)
		return () => clearTimeout(timer)
	}, [isHidden])

	const translate = !show ? `translateY(100%)` : !cookiesAccepted ? `translateY(var(--popup-offset))` : `translateY(0%)`
	const style = {
		opacity: show ? 1 : 0,
		transform: `${translate}`,
		transition: `transform 0.5s var(--popup-easing)`,
		transformOrigin: 'top',
	}

	const handleClose = () => {
		set(false)
		setHidden(true)
	}

	return (
		<div className="newsletter-popup" style={style}>
			<button className="pabs r0 x-icon pad" onClick={handleClose}>{"×"}</button>
			<NewsletterForm />	
		</div>
	)
}

const NewsletterForm = ({ isSMS, listId = LIST_ID, callToAction = "Sign-up to our newsletter:" }) => {

	const [isSubscribed, setSubscribed] = useState(false)

	useEffect(() => {

		const KlaviyoSubscribe = window.KlaviyoSubscribe || null

		if (KlaviyoSubscribe) {
			KlaviyoSubscribe.attachToForms('#email_signup', {
				hide_form_on_success: false,
				extra_properties: {
					$source: '$embed',
					$method_type: "Klaviyo Form",
					$method_id: 'embed',
					$consent_version: 'Embed default text'
				}, 
				success: function (response) {
					setSubscribed(true)
				}
			});
		}
	}, [])

	return (
		!isSubscribed ? (
			<div className="newsletter">
				<form id="email_signup" action="//manage.kmail-lists.com/subscriptions/subscribe" data-ajax-submit="//manage.kmail-lists.com/ajax/subscriptions/subscribe" method="GET" noValidate="novalidate" tabIndex="0">
					<input type="hidden" name="g" value={listId} />
					{/* <input type="hidden" name="$fields" value="$consent,firstname,lastname,shopping_for,$phone_number,$email" readOnly /> */}
					<input type="hidden" name="$list_fields" value="$consent" readOnly />
					<input type="hidden" name="sms_consent" value="true" readOnly />

					<h3 className="no-hover mbx4">{callToAction}</h3>

					{isSMS && (
						<div className="outerx2 fieldgroup">
							<Input type="text" className="input__text" name="firstname" tabIndex="0" id="k_id_firstname" placeholder="First Name" aria-label="First Name" /> 
							<Input type="text" className="input__text"  name="lastname" tabIndex="1" id="k_id_lastname" placeholder="Last Name" aria-label="Last Name" />
						</div>
					)}
					
					<div className="outerx2">
						<Input tabIndex="1" className="email input__text" type="email" value="" name="email" id="k_id_email" placeholder="E-mail address" />
					</div>

					{isSMS && (
						<Input tabIndex="2" className="input__text" type="tel" value="" name="phone_number" id="k_id_phone-number" placeholder="Phone Number (inc. country code)" />
					)}

					<div className="outerx2 flex-spaced">
						<div className="inlb">
							<Checkbox className="input__checkbox inlb mr" tabIndex="3" type="checkbox" name="shopping_for" id="k_id_menswear" value="Menswear" />
							<Checkbox className="input__checkbox inlb mr" tabIndex="4" type="checkbox" name="shopping_for" id="k_id_womenswear" value="Womenswear" />
						</div>
						<button type="submit" className="klaviyo_submit_button btn--bold mr">Submit</button>
					</div>

					<div className="klaviyo_messages block" style={{ color: 'peru' }}>
						<div className="success_message"></div>
						<div className="error_message"></div>
					</div>
				</form>
			</div>
		) : (
			<div className="newsletter">
				<h2>Thank you for subscribing! Check your e-mail for confirmation.</h2>
			</div>
		)
	)
}

const Save = () => (
	<div>
		<input type="tel" autocomplete="tel" name="phone-number" tabindex="0" placeholder="Phone Number" aria-label="Phone Number" aria-invalid="false" class="needsclick undefined kl-private-reset-css-Xuajs1" style="border: none; text-align: left; color: rgb(0, 0, 0); font-family: Geneva, Tahoma, Verdana, sans-serif; font-size: 16px; font-weight: 400; letter-spacing: 0px; background-color: rgba(0, 0, 0, 0); flex-grow: 1;"></input>
	</div>
)

export default NewsletterForm

/*

*/

/*
<div class="klaviyo-form-wpr" >
<span aria-hidden="true" class="close-btn">X</span>
<form id="email_signup" class="klaviyo-form klaviyo_styling klaviyo_gdpr_embed_TtFAHj" action="//manage.kmail-lists.com/subscriptions/subscribe" data-ajax-submit="//manage.kmail-lists.com/ajax/subscriptions/subscribe" method="GET"  novalidate="novalidate" style="display:none">
    <input type="hidden" name="g" value="TtFAHj">
    <input type="hidden"name="$fields" value="$consent,firstname,lastname,shopping_for">
    <input type="hidden" name="$list_fields" value="$consent">
 
    <div class="klaviyo_field_group">
      <label for="k_id_email" class="newsletter-title">Sign up to our newsletter:</label>
      <div class="klaviyo-form-field-group group-half">
        <div class="klaviyo-form-field">
          <input type="text" class="firstname" name="firstname" tabindex="0" id="k_id_firstname" placeholder="First Name" aria-label="First Name" >
        </div>
        <div class="klaviyo-form-field">
          <input type="text" class="lastname"  name="lastname" tabindex="1" id="k_id_lastname" placeholder="Last Name" aria-label="Last Name" >
        </div>
      </div>
      <div class="klaviyo-form-field checkbox-field">
        <div class="checkbox-wpr">
        	<input class="shopping_for"  tabindex="2" type="checkbox" name="shopping_for" id="k_id_menswear" value="Menswear">
        	<label for="k_id_menswear">Menswear</label>
        </div>
        <div class="checkbox-wpr">
        	<input class="shopping_for"  tabindex="3" type="checkbox" name="shopping_for" id="k_id_womenswear" value="Womenswear">
        	<label for="k_id_womenswear">Womenswear</label>
        </div>
      </div>
      <div class="klaviyo-form-field-group group-7_3">
        <div class="klaviyo-form-field">
         <input class="email"  tabindex="4" class="" type="email" value="" name="email" id="k_id_email" placeholder="Email address" />
        </div>
        <button type="submit" class="klaviyo_submit_button">Submit</button>
      </div>
    </div>
  	<div class="klaviyo_messages">
      <!--div class="success_message" style="display:none;"></div-->
      <div class="error_message" style="display:none;"></div>
    </div>
  </form>
</div>
  <style type="text/css">
    .klaviyo_condensed_styling .klaviyo_field_group, .klaviyo_styling .klaviyo_field_group {
        margin-bottom: 0;
    }
    .klaviyo-form-wpr {
        position: fixed;
        bottom: 0px;
        left: 40px;
        background: #090f12;
        color: #fff;
        text-align: left;
        border: 0px;
        margin: 0;
        width: 100%;
        max-width: 460px;
        padding: 60px 40px 40px;
				padding: 35px 40px 30px;
			}
			.klaviyo-form-wpr * {
				font-family: 'basis-grotesque-regular' !important;
			}
			.klaviyo-form-wpr .klaviyo-form {
				display: block !important;
					width: 100%;
					max-width: 100%;
					margin: 0;
			}
			.klaviyo-form-wpr .klaviyo-form .newsletter-title {
					font-size: 12px;
					text-decoration: none;
					font-weight: 100;
					color: #ffffff;
					line-height: 1.1;
					margin-bottom: 15px;
					font-family: 'basis-grotesque-regular' !important;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field {
				margin-bottom: 20px;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field-group {
				display: flex !important;
					margin-right: -15px !important;
					flex-wrap: wrap !important;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field-group > * {
				width: calc(50% - 15px) !important;
					margin-right: 15px !important;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field-group.group-7_3 > *:nth-child(2n+1) {
				width: calc(70% - 30px) !important;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field-group.group-7_3 > *:nth-child(2n+2) {
				width: 30% !important;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="text"], .klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="email"] {
				width: 100%;
					margin: 0px 0;
					height: 30px;
					line-height: 30px;
					text-transform: initial !important;
					background: #090f12;
					border: none;
					border-bottom: 1px solid #fff;
					text-align: left;
					font-size: 12px;
					font-weight: 100;
					padding: 1px 2px;
					border-radius: 0;
					color: #fff;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="text"]::placeholder, .klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="email"]::placeholder { 
				color: rgb(117, 117, 117);
				font: inherit;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="text"]::-webkit-input-placeholder, .klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="email"]::-webkit-input-placeholder { 
				color: rgb(117, 117, 117);
				font: inherit;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="text"]::-moz-placeholder, .klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="email"]::-moz-placeholder { 
				color: rgb(117, 117, 117);
				font: inherit;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="text"]:-ms-input-placeholder, .klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="email"]:-ms-input-placeholder{ 
				color: rgb(117, 117, 117);
				font: inherit;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="text"]:-moz-placeholder, .klaviyo-form-wpr .klaviyo-form .klaviyo-form-field input[type="email"]:-moz-placeholder  { 
				color: rgb(117, 117, 117);
				font: inherit;
			}
			.klaviyo-form-wpr .klaviyo-form .checkbox-field {
				display: flex;
					flex-wrap: wrap;
					margin-right: -30px;
			}
			.klaviyo-form-wpr .klaviyo-form .checkbox-field > * {
				display: flex;
					flex-wrap: wrap;
					margin-right: 50px;
			}
			.klaviyo-form-wpr .klaviyo-form .checkbox-wpr input[type="checkbox"] {
				width: 0;
					height: 0;
					overflow: hidden;
			}
			.klaviyo-form-wpr .klaviyo-form .checkbox-wpr label {
				color: #fff;
					font-size: 12px;
					cursor: pointer;
					font-family: 'basis-grotesque-regular' !important;
					text-transform: none !important;
					font-weight: 100 !important;
					padding-left: 17px !important;
					position: relative;
					cursor: pointer;
					margin-bottom: 4px;
			}
			.klaviyo-form-wpr .klaviyo-form .checkbox-wpr label:before {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					content: "";
					height: 12px;
					width: 12px;
					border: 1px solid #fff;
			}
			.klaviyo-form-wpr .klaviyo-form .checkbox-wpr label:after {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 3px;
					margin: auto;
					content: "";
					height: 6px;
					width: 6px;
					background: #fff;
					opacity: 0;
					transition: 0.3s;
			}
			.klaviyo-form-wpr .klaviyo-form .checkbox-wpr input:checked + label:after {
					opacity: 1;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo_submit_button {
				text-align: center;
					height: 30px;
					line-height: 30px;
					background: none!important;
					color: #FFFFFF !important;
					border-radius: 0 !important;
					font-weight: 100;
					letter-spacing: 1px !important;
					text-transform: uppercase !important;
					border: 1px solid #fff;
					font-size: 12px !important;
					transition: 0.3s;
			}
			.klaviyo-form-wpr .klaviyo-form .klaviyo_submit_button:hover {
				background: #FFF !important;
					color: #000 !important;
			}
			.klaviyo-form-wpr  .close-btn {
					color: #626364;
					cursor: pointer;
					font-size: 14px;
					width: 20px;
					height: 20px;
					position: absolute;
					right: 15px;
					top: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: 'basis-grotesque-mono' !important;
			}
			@media only screen and (max-width: 575px) {
				.klaviyo-form-wpr {
						left: 0;
						padding: 30px 20px 10px;
				}
			}
		</style>
		<script type="text/javascript" src="//www.klaviyo.com/media/js/public/klaviyo_subscribe.js"></script>
		<script type="text/javascript">
		 $(document).ready(function(){ 
		 var $form = $('#email_signup');
			KlaviyoSubscribe.attachToForms('#email_signup', {
				hide_form_on_success: false,
				extra_properties: {
					$source: '$embed',
					$method_type: "Klaviyo Form",
					$method_id: 'embed',
					$consent_version: 'Embed default text'
				},
				success: function ($form) {
				 // console.log($form);
				 // `$form` is a jQuery wrapper around the form that was submitted. This makes it easy to get the email address or form fields.
					$(".klaviyo_submit_button").text("Thank you");
					$(".klaviyo_submit_button").attr('disabled','disabled');
	
				 }
			});
			
			
			$('.close-btn').on('click', function (e) {
			$(".klaviyo-form-wpr").hide();
		});
			 
	});
		</script>
*/