// Dependencies
import React, { useState, useEffect, useRef, useMemo } from "react"
import { useStaticQuery, graphql, Link } from "gatsby";
import { useLocation } from "@reach/router"
import * as JsSearch from 'js-search'
// import { decode } from 'js-base64';
// Hooks & Helpers
// import priceLabel from '../helpers/priceLabel'
import matchProductId from "../helpers/matchProductId"
// Wrappers
// ...
// Components
import ProductThumbnail from "../components/ProductThumbnail"
import { useRolloverState, useThumbnailRollovers } from "../components/Rollovers"
import Input from "../components/Input"

function useContent() {

	const {
    allContentfulProduct
  } = useStaticQuery(SearchDataQuery)

	const sanitizedData = useMemo(() => (
		allContentfulProduct.nodes.map(item => ({
			...item,
			searchByType: item.type?.map(t => t.title) || [] 
		}))
	), [])

	return sanitizedData
}

// Setup the js-seach search index....
function useSearch() {
	
	// Content
	const data = useContent()
	const [searchIndex, setIndex] = useState([])
	const [results, setResults] = useState([])

	// Search query
	const [query, setQuery] = useState("")
	const handleSearchInput = event => setQuery(event.target.value)
	const handleSearchFocus = () => setQuery("")

	// Initialize search index
	useEffect(() => {
		const dataToSearch = new JsSearch.Search('shopifyId')
		dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
		dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
		dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex()
		dataToSearch.addIndex('title')
		dataToSearch.addIndex('colourMaterial')
		dataToSearch.addIndex('searchKeywords')
		dataToSearch.addIndex(['searchByType'])
		dataToSearch.addDocuments(data)
		setIndex(dataToSearch)
	}, [])

	// Update results based on search query
	useEffect(() => {
		if (query && searchIndex) {
			// console.log(searchIndex)
			setResults(searchIndex.search(query))
			window.scrollTo(0, 0)
		} else {
			setResults([])
		}
	}, [query, searchIndex])

	return { query, results, handleSearchInput, handleSearchFocus }
}

const ShopSearchBar = ({ searchState }) => {

	const { query, handleSearchInput, handleSearchFocus } = searchState

	return (
		<div className={`shop__search ${query ? 'active' : ''}`}>
			<svg className="search-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M7.83199 4.4C7.83199 6.29544 6.29544 7.83199 4.4 7.83199C2.50456 7.83199 0.967999 6.29544 0.967999 4.4C0.967999 2.50456 2.50456 0.967999 4.4 0.967999C6.29544 0.967999 7.83199 2.50456 7.83199 4.4ZM6.91245 8.01261C6.2001 8.50895 5.33404 8.79999 4.4 8.79999C1.96995 8.79999 0 6.83005 0 4.4C0 1.96995 1.96995 0 4.4 0C6.83005 0 8.79999 1.96995 8.79999 4.4C8.79999 5.4991 8.397 6.50408 7.7307 7.27524L11.1678 10.7123L10.3899 11.4901L6.91245 8.01261Z" fill="black"/>
			</svg>

			<Input type="text" value={query} onChange={handleSearchInput} onFocus={handleSearchFocus} placeholder="Search" />
		</div>
	)
}

const ProductRolloverImage = ({ images }) => {

	return (
		<div className={``}>
			{images.map((image, index) => <ProductRolloverImage image={image} />)}
		</div>
	)
}

function useGridView(location) {
	
  const [isGallery, set] = useState(false);

	useEffect(() => {
		set(location.hash && location.hash === '#gallery' ? true : false)
	}, [location.hash])

	return isGallery
}

const ShopIndex = ({ products, allShopifyProduct }) => {
	
	const location = useLocation();
	const isGallery = useGridView(location)
	const gridClassName = `grid--shop--outer ${!isGallery ? 'has-thumbnails' : ''}`

	// Search
	const searchState = useSearch()
	const results = searchState.query && searchState.results || products

	// UX
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	// Rollovers
	const [isRolloverActive, enterRollover, leaveRollover] = useRolloverState(true) // isGallery

	return (
		<section>

			<div className="shop-header">
				<div className="shop-header__toggle">
					<Link to={`${location.pathname}${!isGallery ? '#gallery' : ''}`} className="upcase">Switch view</Link>
				</div>
				<div className="shop-header__search">
					<ShopSearchBar searchState={searchState} />
					<div className="account-link">
						<a href="https://shop.songforthemute.com/account" className="btn" target="_blank" rel="noopener noreferrer">Account</a>
					</div>
				</div>
			</div>

			<div className={gridClassName}>
				<section className={`inner grid--shop--inner ${isRolloverActive ? 'has-rollovers' : ''}`}>
					{results?.map((entry, index) => {
						const shopifyProduct = allShopifyProduct.find((item) => {
							return item.variants.find(v => matchProductId(v.storefrontId, entry.shopifyId))
						})
						const product = {
							...shopifyProduct,
							...entry
						}
						const className = `shop__thumb`

						const props = { className, product, isRolloverActive, enterRollover, leaveRollover }

						return (
							product.handle && (
								<ProductThumbnail {...props} debug={index === 4} key={`${entry.shopifyId}-index${index}`} />
							)
						)
					})}
				</section>
			</div>
		</section>
	)
}

const SearchDataQuery = graphql`
  query SearchDataQuery {
		allContentfulProduct {
			nodes {
				shopifyId
				title
				colourMaterial
				type {
					title
				}
				searchKeywords
			}
		}
  }
`

export default ShopIndex