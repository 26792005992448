// Dependencies
import React, { useState, useEffect, useRef } from 'react'


const Input = (props) => {

	const [value, set] = useState('')
	const hasValue = props.value || value

	const handleChange = (event) => set(event.target.value)

	return (
		<div className="input">
			<input {...props} value={props.value || value} onChange={props.onChange || handleChange} />
			<div className={`shadow ${hasValue ? 'active' : ''}`}>{props.value || value || props.placeholder}</div>
		</div>
	)
}

export default Input