import { useRef, useMemo } from 'react'

// useMemo here might be overkill...
function useRandomPosition(x, y) {
  const randomX = useMemo(() => Math.floor((Math.random() * x) - x/2), [])
  const randomY = useMemo(() => Math.floor((Math.random() * (y || x)) - (y || x)/2), [])
	const position = useRef({
		x: randomX,
		y: randomY,
	})
	return position.current
}

export default useRandomPosition