// Dependencies
import React, { useState, useEffect, useRef } from "react"
// import { Link } from "gatsby";
// Hooks & Helpers
// import useVariantInfo from '../hooks/useVariantInfo'
import useRandomPosition from "../hooks/useRandomPosition"
import useTimeout from "../hooks/useTimeout"
// Wrappers
// ...
// Components
// import Image from "../components/Image"

export function useRolloverState(enabled) {

	const timer = useRef()
	const [active, set] = useState(false)

	const enter = () => {
		if (enabled) {
			clearTimeout(timer.current)
			timer.current = setTimeout(() => {
				set(true)
			}, 0)
		}
	}

	const leave = () => {
		if (enabled) {
			clearTimeout(timer.current)
			set(false)
		}
	}

	// Cleanup?
	useEffect(() => {
		// window.addEventListener('scroll', leave)
		return () => {
			// window.removeEventListener('scroll', leave, false)
			clearTimeout(timer.current)
		}
	}, [])

	return [active, enter, leave]
}

export function useThumbnailRollovers(enterRollover, leaveRollover) {
	const [show, set] = useState(false)

	const handleEnter = () => {
		if (window.matchMedia("(hover: none)").matches) {
			return false
		}
		set(true)
		!enterRollover || enterRollover()
	}

	const handleLeave = () => {
		set(false)
		!leaveRollover || leaveRollover()
	}

	return [show, handleEnter, handleLeave]
}

export const RolloverPopup = ({ children, index }) => {

	const ref = useRef()
	// const start = useRandomPosition(400, 100)
	// const startTransform = `translate(${start.x + 20}%, ${start.y + 20}%)`
	// const endTransform = `translate(${start.x}%, ${start.y}%)`
	const startOffset = useRandomPosition(40, 20)
	const offset = useRandomPosition(500, 100)

	const [position, setPosition] = useState({ position: 'absolute' })
	const [show, setShow] = useState(false)

	useTimeout(() => {
		setShow(true)
	}, (index * 500))

	useEffect(() => {
		let { top, left, bottom, right, width } = ref.current.getBoundingClientRect()
		
		top = top + offset.y
		left = left + offset.x

		if (top > 0) {
			if (bottom > window.innerHeight) {
				bottom = 0
				top = 'auto'
			}
		} else {
			top = 0
			bottom = 'auto'
		}

		if ((left + width) > window.innerWidth) {
			console.log('out of bounds!')
			right = 0
			left = 'auto'
		}

		// setPosition({
		// 	position: 'fixed',
		// 	top,
		// 	left,
		// 	bottom,
		// 	right,
		// 	width
		// })
	}, [])

	const style = {
		// transform: show ? endTransform : startTransform,
		...position,
		opacity: show ? 1 : 0,
		transform: !show ? `translate(0%, 0%)` : `translate(${startOffset.x}%, ${startOffset.y}%)`,
		transition: `opacity 0.75s, transform 0.75s`,
		zIndex: 100
	}

	return (
		<div className={`rollovers__thumb`} style={style} ref={ref}>
			{children}
		</div>
	)
}

const Rollovers = ({ children }) => {

	return (
		<div className="rollovers">
			{children}
		</div>
	)
}

export default Rollovers
