import { Base64 } from 'js-base64';

export default function(shopify_id, contentful_id) {
  // if (!shopify_id || !contentful_id) return false

  // if (!Base64.isValid(contentful_id)) {
  //   return false
  // }

  // const decoded_storefront_id = Base64.decode(contentful_id)

  // if (decoded_storefront_id === shopify_id) {
  //   return true
  // } else if (shopify_id === contentful_id) {
  //   return true
  // } else {
  //   return false
  // }

  return shopify_id === contentful_id
}