// Dependencies
import React, { useMemo } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
// ...
// Wrappers
import Layout from "../wrappers/Layout"
// Components
import ShopIndex from "../components/ShopIndex"
import { NewsletterPopup } from "../components/NewsletterForm"

function useFilteredProducts(products, productType) {
  const filtered = () => {
    // If no products, return and empty array/list
    if (!products) {
      return []
    }

    if (productType) {
      // If there is a product type, filter through the products and see if any of their types match
      return products.filter((p) =>
        p?.type ? p.type.find((t) => t?.slug === productType) : []
      )
    } else {
      // If no productType (on a 'All' page), then filter out any products with a 'Sale' type
      return products.filter((p) => {
        const hasHideFromAll = p.type && p.type.find((t) => t?.hideFromAll)
        return !hasHideFromAll
        // const hasSale = p.type && p.type.find(t => t?.slug === 'sale')
        // const hasGifting = p.type && p.type.find(t => t?.slug === 'gifting')
        // return !hasSale && !hasGifting
      })
    }
  }
  // eslint-disable-next-line
  return useMemo(filtered, [])
}

const ShopIndexTemplate = ({ data, pageContext }) => {
  // Content
  const { title } = data.contentfulProductDepartment
  const allShopifyProduct = data.allShopifyProduct.nodes
  const products = useFilteredProducts(
    data.contentfulProductDepartment.products,
    pageContext.productType
  )

  return (
    <Layout
      title={
        pageContext.productType
          ? `${title} / ${pageContext.productTypeTitle}`
          : title
      }
    >
      <ShopIndex products={products} allShopifyProduct={allShopifyProduct} />
      <NewsletterPopup />
    </Layout>
  )
}

ShopIndexTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ShopIndexTemplate

// TODO: Order of projects not based on department reference fields
export const ShopIndexTemplatePageQuery = graphql`
  query ShopIndexTemplateQuery($productDept: String) {
    contentfulProductDepartment(slug: { eq: $productDept }) {
      title
      products {
        shopifyId
        title
        colourMaterial
        comingSoon
        comingSoonLabel
        type {
          slug
          hideFromAll
        }
        thumbnails {
          gatsbyImageData(layout: FULL_WIDTH)
          medium: gatsbyImageData(layout: CONSTRAINED, width: 640)
          large: gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    allShopifyProduct {
      nodes {
        title
        handle
        featuredImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        images {
          gatsbyImageData(layout: FULL_WIDTH)
          medium: gatsbyImageData(layout: CONSTRAINED, width: 640)
          large: gatsbyImageData(layout: FULL_WIDTH)
        }
        variants {
          availableForSale
          storefrontId
          price
          compareAtPrice
          selectedOptions {
            value
          }
        }
        options {
          name
          values
        }
      }
    }
  }
`
// filter: {product___department: {elemMatch: {slug: {eq: $productDept}}}, type: {elemMatch: {slug: {eq: "outerwear"}}}}
//($productType: String!)
//($productType: String!, $productDept: String!)
//($slug: String!)
